import "./App.css";
import globalStyle from "./styles/global.style.js";
import "animate.css/animate.min.css";

import { Layout } from "./styles/theme.config.js";
import Navigation from "./components/Navigation/Navigation";
import Hero from "./components/Hero/Hero";
import Products from "./components/Products/Product";
import About from "./components/About/About";
import Description from "./components/Description/Description";
import More from "./components/More/More";
import Artisans from "./components/Artisans/Artisans";
import ImageGallery from "./components/ImageGallery/ImageGallery";
import Sustainability from "./components/Sustainability/Sustainability";
import Contact from "./components/Contact/Contact";
import Footer from "./components/Footer/Footer";
import HeroBackground from "./components/HeroBackground/HeroBackground";
import data from "./data.js";
import Header from "./components/header/Header";

const App = () => {
  globalStyle();
  return (
    <Layout>
      <Navigation logo={data.logo} companyName={data.companyName} />
    

      {/* <Hero
        background={data.background}
        mainText={data.mainText}
        subText={data.subText}
      /> */}

      <Header
        background={data.headerData.image}
        title={data.headerData.title}
        subTitle={data.headerData.subTitle}
        mark={data.headerData.mark}
        certificates={data.header_imageArray}
      />

<HeroBackground
        background={data.background}
        mainText={data.mainText}
        subText={data.subText}
      />

      <About data={data.aboutData} header={data.about} />

      <Products
        productSectionTitle={data.productSectionTitle}
        prodArray={data.prodArray}
        buttonContent={data.buttonContent}
      />

      <Description />

      <Sustainability data={data.sustainabilityData} />

      <ImageGallery
        id="certifications"
        title={data.certificationsTitle}
        imgArray={data.imgArray}
        isCarousel={true}
      />

      {/* <Artisans data={data.artisansData} /> */}

      {/* <More /> */}

      <Contact  {...data}/>

      <Footer
        logo={data.logo}
        companyName={data.companyName}
        prodArray={data.prodArray}
        brandArray={data.brandArray}
      />
    </Layout>
  );
};

export default App;
