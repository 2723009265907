import * as S from "./Footer.style.js";
import sourcewiz from "./../../assets/sourcewiz.png";
import { useEffect, useState } from "react";
import Mail from "./../../assets/mail.svg";
import Location from "./../../assets/map.svg";
import Phone from "./../../assets/phone.svg";

const links = [
  {
    title: "Home",
    link: "/#home",
  },
  {
    title: "Products",
    link: "/#products",
  },
  {
    title: "About us",
    link: "/#about",
  },
  {
    title: "Contact us",
    link: "/#contact",
  },
];

const Footer = ({ logo, companyName, prodArray, brandArray }) => {
  let [currentYear, setCurrentYear] = useState(0);

  useEffect(() => {
    let date = new Date().getFullYear();
    setCurrentYear(date);
  }, []);

  return (
    <>
      <S.Container>
        <S.Content>
          <S.Box>
            <S.CompanyCred>

              
              <S.Desc>
               
              <S.Image src={logo} />

              <S.Headings>

              <S.Header>{companyName}</S.Header>
              <S.Info>Plastic Recycling at its best</S.Info>



              </S.Headings>
                



              </S.Desc>

              {/* <S.Content>
              
                
              
              </S.Content> */}

              <S.Content>
                <div style={{ margin: "0.6em 3em 0.6em 0" }}>
                  Powered by{" "}
                  <a href="https://sourcewiz.co/" target="blank">
                    <S.Image src={sourcewiz} style={{ margin: "auto 0.5em" }} />
                  </a>
                </div>
              </S.Content>
            </S.CompanyCred>
          </S.Box>

          <S.Box>
            <S.Contain>
              <S.Icon src={Location} style={{marginRight : '0.5em'}}/>
              <S.BoldText>Regd off:</S.BoldText>
            </S.Contain>

            <S.InfoText>
              1309, Coffeen Avenue STE 1200
              <br />
              Shreidan, Wyoming 82801 USA
            </S.InfoText>

            <S.Contain>
              <S.Icon src={Location} style={{marginRight : '0.5em'}}/>

              <S.BoldText>Corp off</S.BoldText>
            </S.Contain>

            <S.InfoText>
              18571 Colima Rd, Suite D, Rowland
              <br />
              Heights, California 91748 USA
            </S.InfoText>

            <S.Contain>
              <S.Icon src={Phone} style={{marginRight : '0.5em'}}/>

              <S.BoldText>(213) 292 4015</S.BoldText>
            </S.Contain>

            <S.Contain>
              <S.Icon src={Mail} style={{marginRight : '0.5em'}}/>

              <S.BoldText>firstimperialmarkets@gmail.com</S.BoldText>
            </S.Contain>
          </S.Box>

          <S.Box>
            <S.BoldText>Products</S.BoldText>
            {prodArray.map((value, key) => (
              <S.ListText href={value.link} target="blank">
                {value.title}
              </S.ListText>
            ))}
          </S.Box>

          {/* <S.Box>
                    <S.BoldText>Brands we work with</S.BoldText>
                    <S.Content>
                        <S.BrandBox>{brandArray.map((value, key) => <S.ListText href={value.link} target="blank">{value.title}</S.ListText>)}</S.BrandBox>
                    </S.Content>
                </S.Box> */}

          <S.Box>
            <S.BoldText>Quick links</S.BoldText>
            {links.map((value, key) => (
              <S.ListText href={value.link}>{value.title}</S.ListText>
            ))}
          </S.Box>
        </S.Content>
      </S.Container>
      <div
        style={{
          color: "#B4B7B6",
          width: "100vw",
          textAlign: "center",
          background: "#1D2624",
          color: "white",
          height: "25px",
        }}
      >
        Copyright @{companyName} {currentYear - 1}-{currentYear}
      </div>
    </>
  );
};

export default Footer;
