import Logo from "./assets/logo.png";
import Background from "./assets/Hero_image.png";
import Product_1 from "./assets/Product_1.png";
import Product_2 from "./assets/Product_2.png";
import Product_3 from "./assets/Product_3.png";
import Product_4 from "./assets/Product_4.png";
import Product_5 from "./assets/Product_5.png";
import Product_6 from "./assets/Product_6.png";
import Product_7 from "./assets/Product_7.png";
import Product_8 from "./assets/Product_8.png";
import Product_9 from "./assets/Product_9.png";
import Product_10 from "./assets/Product_10.png";

import About_1 from "./assets/About_1.png";
import About_2 from "./assets/About_2.png";
import About_3 from "./assets/About_3.png";
import About_4 from "./assets/About_4.png";

import artisans from "./assets/artisans.png";
import sustainability from "./assets/sustainability.png";
import header_image from "./assets/header_image.png";

// import headerimage_1 from "./assets/headerimage_1";
// import headerimage_2 from "./assets/headerimage_2";

import product_img1 from "./assets/product_img1.png";
import product_img2 from "./assets/product_img2.png";
import product_img3 from "./assets/product_img3.png";
import product_img4 from "./assets/product_img4.png";
import product_img5 from "./assets/product_img5.png";
import product_img6 from "./assets/product_img6.png";
import product_img7 from "./assets/product_img7.png";
import product_img8 from "./assets/product_img8.png";

// certificates
import certificate1 from "./assets/certificate1.png";
import certificate2 from "./assets/certificate2.png";
import certificate3 from "./assets/certificate3.png";

import header1 from "./assets/header1.png";
import header2 from "./assets/header2.png";
import header3 from "./assets/header3.png";

const data = {
  companyName: "First Imperial Markets",
  mainText: "Merging ideas & shaping a better future",
  subText:
    "We have 50 years experience in the plastic industry, dating back to 1965, when our visionary Late Madhu B. Sawant, stepped into this field to consistently deliver industry-leading performance.",
  productSectionTitle: "Our Products",
  brandsTitle: "Brands we work with",
  certificationsTitle: "Certifications",
  buttonContent: "Send an enquiry",
  logo: Logo,
  background: Background,
  prodLinkArray: [
    {
      title: "Rugs",
      link: "/#",
    },
    {
      title: "Pillows",
      link: "",
    },
    {
      title: "Poufs",
      link: "",
    },
    {
      title: "Wall hangers",
      link: "/#",
    },
  ],

  brandArray: [
    {
      title: "Company 1",
      link: "/#",
      image: Background,
    },
    {
      title: "Company 2",
      link: "/#",
      image: Background,
    },
    {
      title: "Company 3",
      link: "/#",
      image: Background,
    },
    {
      title: "Company 4",
      link: "/#",
      image: Background,
    },
    {
      title: "Company 5",
      link: "/#",
      image: Background,
    },
    {
      title: "Company 6",
      link: "/#",
      image: Background,
    },
    {
      title: "Company 7",
      link: "/#",
      image: Background,
    },
    {
      title: "Company 8",
      link: "/#",
      image: Background,
    },
  ],

  prodArray: [
    {
      title: "Granular Powder",
      image: product_img1,
      link: "https://catalogue.sourcewiz.co/1xmqsqr",
    },
    {
      title: "HDPE IBC and DRUM REGRIND",
      image: product_img2,
      link: "https://catalogue.sourcewiz.co/bb7av6n",
    },
    {
      title: "LDPE Bales and Rolls",
      image: product_img3,
      link: "https://catalogue.sourcewiz.co/rrh73g0",
    },
    {
      title: "LDPE Grade a Film in Bales",
      image: product_img4,
      link: "https://catalogue.sourcewiz.co/axa5vhe",
    },
    {
      title: "LDPE Granular/Fine Powder Combo",
      image: product_img5,
      link: "https://catalogue.sourcewiz.co/2l112sk",
    },
    {
      title: "PP Off Grade Pellets",
      image: product_img6,
      link: "https://catalogue.sourcewiz.co/gbg7lms",
    },
    {
      title: "LDPE with 6% Nylon Repro",
      image: product_img7,
      link: "https://catalogue.sourcewiz.co/37d1sh1",
    },

    {
      title: "Pure LDPE Roll",
      image: product_img8,
      link: "https://catalogue.sourcewiz.co/96v81kc",
    },
  ],

  // prodArray: [
  //   { title: "Ganga", image: Product_1, link: "" },
  //   {
  //     title: "Grass",
  //     image: Product_2,
  //     link: "",
  //   },
  //   {
  //     title: "Majestic",
  //     image: Product_3,
  //     link: "",
  //   },
  //   {
  //     title: "Courtyard",
  //     image: Product_4,
  //     link: "",
  //   },
  //   {
  //     title: "Cosmo",
  //     image: Product_5,
  //     link: "",
  //   },
  //   {
  //     title: "Vogue",
  //     image: Product_6,
  //     link: "",
  //   },
  //   {
  //     title: "Saffire",
  //     image: Product_7,
  //     link: "",
  //   },
  //   {
  //     title: "Pearl",
  //     image: Product_8,
  //     link: "",
  //   },
  //   {
  //     title: "Mamluks",
  //     image: Product_9,
  //     link: "",
  //   },
  //   {
  //     title: "Cyprus",
  //     image: Product_10,
  //     link: "",
  //   },
  // ],
  imgArray: [
    {
      image: certificate1,
    },
    {
      image: certificate2,
    },
    {
      image: certificate3,
    },
  ],

  aboutData: [
    {
      image: About_1,
    },
    {
      image: About_2,
    },
    {
      image: About_3,
    },
    {
      image: About_4,
    },
  ],

  sustainabilityData: {
    title: "Sustainability is the way of life, the way of our manufacturing",
    text: "",
    image: sustainability,
  },

  artisansData: {
    title:
      "The artisans are the masters behind creating our world class products",
    text: "",
    image: artisans,
  },

  headerData: {
    image: header_image,
    title: "Welcome to First Imperial Markets",
    subTitle: "Plastic recycling at its best",
    mark: "Making a mark since 1965",
  },

  header_imageArray: [
    {
      image: header1,
    },
    {
      image: header2,
    },
    {
      image: header3,
    },
  ],

  emails : ['firstimperialmarkets@gmail.com'],
 
};

export default data;
