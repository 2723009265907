import * as S from "./Header.style.js";

const Header = ({ background, title, subTitle, mark, certificates}) => {
  return (
    <S.Container
      id="home"
      style={{ backgroundImage: 'url("' + background + '")' }}
    >
      <S.Box>
        <S.Content>
          <S.Header className="heading">{title}</S.Header>
          <S.Para>{subTitle}</S.Para>
          <S.Mark>{mark}</S.Mark>
          <S.Button href="/#products">View products</S.Button>
        </S.Content>
       
      </S.Box>

      <S.ImageContainer>

      {certificates?.map((value, key) => (
                        
                        <S.Product src={value.image}/>
                   
               ))} 

      </S.ImageContainer>

      

      
    </S.Container>
  );
};

export default Header;
