import * as S from "./Sustainability.style.js";
import Animation from "react-animate-on-scroll";

const Sustainability = ({ data }) => {
  return (
    <Animation animateIn="animate__fadeInUp" animateOnce={true}>
      <S.Container>
        <S.Header>{data.title}</S.Header>
        <S.Image src={data.image} />
        <S.Text>
          We at First Imperial Markets Inc. understand that in today's global
          marketplace, sustainability must be ingrained in our business
          practices. We define sustainability as a journey for our company that
          involves ongoing learning, adaptation, and improvement of our core
          business operations, as well as, our responses to the demands of our
          many vendors and customers.
          <br />
          <br />
          We're aiming to maximize our effect, achieve our goals, and guarantee
          that our fundamental business plan is viewed through a sustainable
          perspective. Recycling plastic in the environment is a pressing
          concern of our day. There is no other answer to this problem. To
          develop a circular economy for plastics, we are focusing on a mix of
          initiatives.
          <br />
          <br />
          We are focusing on two revolutionary areas to help address some of the
          world's most critical challenges: recycling plastic waste to help the
          environment and promoting sustainable solutions.
          <br />
          <br />
          {/* We at First Imperial Markets Inc. understand that in today's global
          marketplace, sustainability must be ingrained in our business
          practices. We define sustainability as a journey for our company that
          involves ongoing learning, adaptation, and improvement of our core
          business operations, as well as, our responses to the demands of our
          many vendors and customers.

           We're aiming to maximize our effect,
          rethink our goals, and guarantee that our fundamental business plan is
          viewed through a sustainable perspective. Recycling plastic in the
          environment is a pressing concern of our day. There is no other answer
          to this problem. To develop a circular economy for plastics, we are
          focusing on a mix of initiatives.

          
           We are focusing on two revolutionary
          areas to help address some of the world's most critical challenges:
          recycling plastic waste to help the environment and promoting
          sustainable solutions. */}
        </S.Text>
      </S.Container>
    </Animation>
  );
};

export default Sustainability;
