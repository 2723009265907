import * as S from "./HeroBackground.style.js";

import Animation from "react-animate-on-scroll";

const HeroBackground = ({ background, mainText}) => {

  
  return (
    <S.Container>
    <S.Box>
      <S.Content>
        <Animation animateIn="animate__fadeInLeft" animateOnce={true}>
          <S.Heading>{mainText}</S.Heading>
          <S.Para>

            <ul>
              <li>We have 57 years experience in the plastic industry, dating back to 1965</li>

            </ul>
            <ul>
              <li>Certificate of good standing</li>

            </ul>
            <ul>
              <li>Member Los Angeles Chamber of Commerce</li>

            </ul>
            <ul>
              <li>D-U-N-S® Number is: 118383698 </li>

            </ul>


          
           
          </S.Para>
        </Animation>
      </S.Content>
      <S.Content>
        <Animation animateIn="animate__fadeInRight" animateOnce={true}>
          <S.Image src={background} />
        </Animation>
      </S.Content>
    </S.Box>

    </S.Container>
    
  );
};

export default HeroBackground;
