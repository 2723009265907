import { styled } from "./../../styles/theme.config.js";

export const Container = styled("div", {
  objectFit: "cover",
  height: "100vh",
  zIndex: -1,
  backgroundPosition: "center",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",

  "@media only screen and (max-width : 1000px)": {},

  "@media only screen and (max-width : 600px)": {
     height : "95vh"
  },
});

export const Box = styled("div", {
  display: "flex",
  marginTop: "2em",
  alignItems: "center",
  justifyContent: "center",
  "@media only screen and (max-width : 1000px)": {
    flexDirection: "column",
    marginTop: "2em",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  },
});

export const Content = styled("div", {
  display: "flex",
  flexDirection: "column",
  padding: "5em 1em 0 1em",
  justifyContent: "center",
  margin : '$2',
  "@media only screen and (max-width : 1000px)": {
    height: "initial",
    margin : '$2',
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
});

export const Header = styled("h2", {
  fontStyle: "italic",
  color: "$golden",
  fontWeight: "700",
  textAlign: "center",
  lineHeight: "$2",
  marginTop : '2em',
  fontSize : '56px',

  "@media only screen and (max-width : 425px)" : {

    fontSize : '26px',

  }
 
});

export const Para = styled("p", {
  fontStyle: "italic",
  fontWeight: "400",
  textAlign: "center",
  fontSize : '40px',
  marginTop : '0.75em',
  "@media only screen and (max-width : 425px)" : {
      fontSize : '20px'
  }
 
 
});

export const Mark = styled("p", {
  fontStyle: "italic",
  fontWeight: "400",
  fontSize : '32px',
  textAlign: "center",
  marginTop : '0.75em',
  "@media only screen and (max-width : 425px)" : {
    fontSize : '16px'
  }
 
  
});

export const Button = styled("a", {
  display: "flex",
  flexDirection : "column",
  justifyContent: "center",
  alignItems: "center",
  background: "$button",
  fontWeight: "600",
  textAlign : 'center',
  width : '200px',
  height: "3em",
  padding: "0.5em 0.5em",
  margin: "3em 0",
  borderRadius: "4px",
  border: "none",
  color: "$white",
  textDecoration: "none",
  "&:hover": {
    color: "$white",
  },
});

export const Product = styled("img", {
   
  margin : '1em',

  "@media only screen and (max-width : 550px)": {
    margin : '0.3em',
    width : '65px',
    
  },


  
});

export const ImageContainer = styled("div", {
  display: "flex",
  justifyContent: "center",
  width : '100%',
  margin: "0 auto",
  "@media only screen and (max-width : 550px)": {
    display: "flex",
    alignItems : "center",
    width : '68px',
    flexWrap : 'nowrap',


    
  },
  
});
