import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

import Animation from "react-animate-on-scroll";

// <S.Container id="about">
//     {data.map((value, key) => (
//         key % 2 == 0
//         ?
//         <S.Box>
//             <S.Content>
//                 <S.Header>
//                     {value.title}
//                 </S.Header>
//                 <S.Text>
//                     {value.text}
//                 </S.Text>
//             </S.Content>
//             <S.Content>
//                 <S.Image src={value.image}/>
//             </S.Content>
//         </S.Box>
//         :
//         <S.Box>
//             <S.Content>
//                 <S.Image src={value.image}/>
//             </S.Content>
//             <S.Content>
//                 <S.Header>
//                     {value.title}
//                 </S.Header>
//                 <S.Text>
//                     {value.text}
//                 </S.Text>
//             </S.Content>
//         </S.Box>
//     ))}
// </S.Container>

//     )
// }

import * as S from "./About.style.js";
import globalStyle from "../../styles/global.style";

const About = ({ data }) => {
  globalStyle();

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    adaptiveHeight: true,
  };

  return (
    <Animation animateIn="animate__fadeInUp" animateOnce={true}>
      <S.Container id="about">
        <S.Box>
          <S.Header>About us</S.Header>
          <S.Text>
            First Imperial Markets Inc. is a company run by highly experienced
            individuals from the plastic industry. Our vision is to leave a
            better carbon footprint behind by sourcing various types of plastic
            scrap from the United States and manufacturing pellets, hence
            preventing them from ending up in landfills and harming the
            environment indefinitely.
            <br />
            <br />
             We at First Imperial Market Inc. have 57
            years of experience in the industry since 1965, when our visionary
            stepped into this field. Since our foundation, we have been at the
            forefront of manufacturing plastics and supplying our products
            worldwide.
          </S.Text>
        </S.Box>
        <S.Content>
          <Slider {...settings}>
            {data.map((value, key) => (
              <S.Image src={value.image} />
            ))}
          </Slider>
        </S.Content>
      </S.Container>
    </Animation>
    // <S.Container id="about">
    //     {data.map((value, key) => (
    //         key % 2 == 0
    //         ?
    //         <S.Box>
    //             <S.Content>
    //                 <S.Header>
    //                     {value.title}
    //                 </S.Header>
    //                 <S.Text>
    //                     {value.text}
    //                 </S.Text>
    //             </S.Content>
    //             <S.Content>
    //                 <S.Image src={value.image}/>
    //             </S.Content>
    //         </S.Box>
    //         :
    //         <S.Box>
    //             <S.Content>
    //                 <S.Image src={value.image}/>
    //             </S.Content>
    //             <S.Content>
    //                 <S.Header>
    //                     {value.title}
    //                 </S.Header>
    //                 <S.Text>
    //                     {value.text}
    //                 </S.Text>
    //             </S.Content>
    //         </S.Box>
    //     ))}
    // </S.Container>
  );
};

export default About;
