import { styled } from './../../styles/theme.config.js';

export const Container = styled('div', {
    padding : '5em 10em',
    display : 'flex',
    flexDirection : 'column',
    justifyContent : 'center',
    "@media only screen and (max-width : 600px)": {
        padding : '3em'
    },
})

export const Header = styled('h2', {
    textAlign : 'center',
    margin : '0.5em',
    color : '$golden',
})

export const Image = styled('img',{
    width: '180px',
    display: 'block',
    margin: 'auto',
    padding : '0em',
    "@media only screen and (max-width : 600px)": {
        width : '70vw'
    },
})

export const Content = styled('div',{
    border : 'none !important',
    margin : '2em 0',
    padding : 0,
})

export const Box = styled('div', {
    display : 'flex',
    flexWrap : 'wrap',
    width : '100%',
    justifyContent : 'center',
    margin : '0 auto',
})

export const Product = styled('img', {
    width : '200px',
    gap : '5em',
    display : 'flex',

})

export const Link = styled('a', {
    textDecoration : 'none',
})