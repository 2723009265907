import { styled } from './../../styles/theme.config.js';

export const Container = styled('div', {
    display        : 'flex',
    justifyContent : 'center',
    alignItems     : 'center',
    flexDirection  : 'column',
    width          : '100vw',
    zIndex         : -1,
    backgroundColor: '$grey',
    color          : '$white',
    padding : '1.5em 0 0 1em',
    '@media only screen and (max-width : 600px)' :{
        alignItems     : 'flex-start',
    }
})

export const Content = styled('div', {
    display : 'flex',
    justifyContent : 'space-between',
    lineHeight : '2em',
    '@media only screen and (max-width : 600px)' :{
        flexDirection : 'column',
        alignItems : 'flex-start',
        justifyContent : 'left',
    },
})

export const Box = styled('div', {
    margin : '3em 2em',
    '@media only screen and (max-width : 600px)' :{
        margin : '1em 1em',
    },
})

export const CompanyCred = styled('div', {
    display : 'flex',
    height : '100%',
    flexDirection : 'column',
    justifyContent : 'space-between',
})

export const Header = styled('h1', { 
  
     fontStyle : 'italic',
     
     '@media only screen and (max-width : 600px)' :{
         fontSize : '$4',
        
     }
   
})

export const Text = styled('p', {
    margin : '1em 0',
    color : '#cccccc',
})

export const BoldText = styled('p', {
    margin : '1em 0',
    fontWeight : 'bold',
})

export const ListText = styled('a', {
    margin : '1em 2em 1em 0',
    display : 'block',
    textDecoration : 'none',
    color : '$white',
    '&:hover' : {
        color : '#cccccc',
    }
})

export const BrandBox = styled('div', {
    display : 'flex',
    maxWidth : '15em',
    flexWrap : 'wrap',
    justifyContent: 'space-between',
    'a' : {
        margin : '0em 2em 1em 0',
    },
    
    '@media only screen and (max-width : 600px)' :{
        padding : 0,
        display : 'block'
    },
})

export const ProdBox = styled('div', {
    '@media only screen and (max-width : 600px)' :{
        padding : 0
    },
})

export const Image = styled('img', {
    width : '60px',
    margin : 'auto 0em'
})

export const InfoText = styled('p' , {


   color : '#fff',
   fontWeight : '400',
   fontSize : '1em',

   



})

export const Contain = styled('div' , {

      display : 'flex',
      alignItems : 'center',

})

export const Desc = styled('div' , {

  display : 'flex',
  alignItems : 'center',
  justifyContent : 'space-between',
 


})


export const Icon = styled('img' , {

    width : '15px',


})

export const Headings = styled( 'div', {

   display : 'flex',
   flexDirection : 'column',
    marginTop : '0px',
    marginRight : '1em',
    marginBottom : '0px',
    marginLeft : '1em',
    '@media only screen and (max-width : 600px)' :{
        fontSize : '$4',
     
    }


})

export const Info = styled('p' , {

    color : '#fff',
    fontWeight : '400',
    fontSize : '1em',
    fontStyle : 'italic',
    '@media only screen and (max-width : 600px)' :{
        fontSize : '0.75em',
        
    }
    
})